import { render, staticRenderFns } from "./Performance.vue?vue&type=template&id=4ac25094&scoped=true"
import script from "./Performance.vue?vue&type=script&lang=js"
export * from "./Performance.vue?vue&type=script&lang=js"
import style0 from "./Performance.vue?vue&type=style&index=0&id=4ac25094&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ac25094",
  null
  
)

export default component.exports